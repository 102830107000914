<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-24 10:26:00
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-24 11:12:51
-->
<template>
    <div class="history-class">
        <div class="history-class-top">
            <div class="history-class-top-leftTitle">年生态流量统计</div>
            <div class="history-class-top-rightTitle">
                <span class="history-span">年份</span>
                <el-select v-model="queryYear" placeholder="请选择" style="margin-right: 10px;">
                    <el-option
                        v-for="(item, index) in yearOptions"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-button type="primary" @click="queryClick">查询</el-button>
                <el-button type="primary" @click="exportClick">导出</el-button>
            </div>
        </div>
        <div class="history-class-bottom">
            <el-table :data="tableData" border style="width: 98%">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column
                    v-for="(item, index) in tableCols"
                    :key="index"
                    :prop="item.prop"
                    :label="item.label" > 
                </el-table-column>
            </el-table>
            <div class="block nextpage">
                <el-pagination @size-change="handleSizeChange" 
                    @current-change="handleCurrentChange" 
                    :current-page="currentPage" 
                    :page-sizes="[8, 16, 32, 64]" 
                    :page-size="pagesize" 
                    layout="total, sizes, prev, pager, next, jumper" 
                    :total="tableData.length">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            queryYear: '',
            yearOptions: [
                { value: '2019', label: '2019' },
                { value: '2020', label: '2020' },
                { value: '2021', label: '2021' },
            ],
            tableCols: [
                {prop: 'startTime', label: '勉考核开始时间'},
                {prop: 'endTime', label: '勉考核结束时间'},
                {prop: 'reason', label: '勉考核原因'},
                {prop: 'operator', label: '操作员'},
                {prop: 'remark', label: '备注'},
                {prop: 'state', label: '提交状态'},
            ],
            tableData: [
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
                {startTime: '2021-10-24', endTime: '2021-10-24', reason: '安全', operator: '张三', remark: '无', state: '提交'},
            ],
            currentPage: 1, //初始页
            pagesize: 8, //每页的数据
        };
    },
    components: {
    },
    created() {
    },
    watch: {
        
    },
    methods: {
        /**
         * @description: 查询事件
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        queryClick(){
            alert("查询");
        },
        /**
         * @description: 导出事件
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        exportClick(){
            alert("导出");
        },
        /**
         * @description: 切换页数
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleSizeChange(val) {
            this.pagesize = val;
        },
        /**
         * @description: 切换页码
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleCurrentChange(val) {
            this.currentPage = val;
        },
    }
}
</script>
<style>
.history-class{
    width: 100%;
    height: 100%;
}
.history-class-top{
    width: 100%;
    height: 50PX;
}
.history-class-bottom{
    width: 100%;
    height: 100%;
}
.history-class-top-leftTitle{
    width: 200px;
    font-size: 20px;
    float: left;
    margin-top: 8px;
    margin-left: 10px;
}
.history-class-top-rightTitle{
    float: right;
    margin-top: 2px;
    margin-right: 8px;
}
.history-span{
    font-size: 20px;
    margin-right: 10px;
}
</style>

